import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import WelcomeScreen from '../components/WelcomeScreen';
import ProtectedRoute from './protectedRoute';

const Home = lazy(() => import('../screens/Home'));
const CASuccess = lazy(() => import('../screens/CASuccess'));

const routes = () => {
  return (
    <>
      <Suspense fallback={<WelcomeScreen />}>
        <Router>
          <Routes>
            {/* Routes that include the Header & Footer */}
            <Route>
              <Route element={<ProtectedRoute />}>
                <Route path="/" element={<Home />} />
              </Route>
            </Route>
            {/* CA Success Page - No Header/Footer */}
            <Route path="/ca_success" element={<CASuccess />} />
          </Routes>
        </Router>
      </Suspense>
    </>
  )
}
export default routes;