import React from 'react';
import { Menu, Row, Col } from 'antd';
import logo from '../assets/images/logo.png'

const Header = () => {
  const handleScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };
  return (
    <>
      <div className='header footer-bg bg-light space pb-4'>
        <div className="container ">
          <Row justify={'center'}>
            <Col lg={24}>
              <div className="logo">
                <img src={logo} />
              </div>
            </Col>
            <Col lg={24} className='pt-4'>
              <Menu mode="horizontal" defaultSelectedKeys={['1']}>
                <Menu.Item key="1" onClick={() => handleScroll('about-us')}>
                  About Us
                </Menu.Item>
                <Menu.Item key="2" onClick={() => handleScroll('services')}>
                  Our Services
                </Menu.Item>
                <Menu.Item key="3" onClick={() => handleScroll('download')}>
                  Download
                </Menu.Item>
                <Menu.Item key="5" onClick={() => handleScroll('contact-us')}>
                  Contact Us
                </Menu.Item>
              </Menu>
            </Col>
          </Row>
        </div>
      </div>
      <div className="footer">
        <p className='text-white'>&copy;2025-All Rights Reserved - FALCBROS LLC</p>
      </div>
    </>
  );
};
export default Header;